<template>
	<div class="loginbg">
		<!-- :style="{backgroundImage:'url('+bgidx+')'}" -->
		<div class="bgvideo">
			<video :src="bgvideourl" :poster="bgidx" loop="loop" controls="controls" autoplay muted="muted"
				preload="auto" style="width: 1920px; height: 1080px;">


			</video>
		</div>
		<div class="login_box">
			<div style="text-align: center; padding-bottom: 20px; font-size: 24px; font-weight: bold;">录取查询系统</div>
			<div class="coricon" @click="changeLoginType">
				<img v-if="!showQrcode" :src="'./images/icon-qrcode.png'" alt="">
				<img v-else :src="'./images/icon-pwd.png'" alt="">
			</div>
			<div v-if="!showQrcode">


				<el-form :model="EditItem">

					<el-form-item>

						<el-input placeholder="请输入账号" @keyup.enter.native="login" v-model="username">
							<template slot="prepend">账 号</template>
						</el-input>

					</el-form-item>
					<el-form-item>

						<el-input type="password" placeholder="请输入密码" @keyup.enter.native="login" v-model="password">
							<template slot="prepend">密 码</template>
						</el-input>

					</el-form-item>
					<!-- <el-form-item>

						<div style="width: 60%; float: left;">
							<el-input type="text" placeholder="请输入验证码" @keyup.enter.native="login" v-model="yzm">

							</el-input>
						</div>
						<div style="margin-left: 60%; height: 40px; overflow: hidden;" @click="changeYzm">
							<div style="border: 0; width: 150px; height: 50px; transform: scale(0.8,0.8); transform-origin: left top;"
								v-html="yzmUrl">
							</div>

						</div>


					</el-form-item> -->

					<div style="height: 20px; text-align: center; padding-top: 50px;">
						<el-button type="primary" style="width: 100%;" @click="login">登录</el-button>
					</div>
				</el-form>

			</div>

			<div v-else>
				<div style="width: 60%; margin: 0 auto; padding-top: 30px;">
					<img :src="qrcodeUrl?qrcodeUrl:'../../images/qrcode.jpg'" alt="" style="width: 100%;">
				</div>

			</div>



		</div>

		<el-dialog title="初次登录请先修改密码" :visible.sync="isShowModal" :close-on-click-modal="false" :destroy-on-close="true"
			width="500px">
			<div style="text-align: center; color: orangered; padding-bottom: 20px;">密码为长度8-16位的字符、数字和特殊字符的组合</div>
			<el-form :model="pwdForm">

				<el-form-item>

					<el-input type="password" placeholder="请输入新密码" v-model="pwdForm.newPwd1" auto-complete="off">
						<template slot="prepend"><span style="display: inline-block;width: 60px;">修改密码</span></template>
					</el-input>

				</el-form-item>

				<el-form-item>

					<el-input type="password" placeholder="请再次输入新密码" v-model="pwdForm.newPwd2" auto-complete="off">
						<template slot="prepend"><span style="display: inline-block;width: 60px;">确认密码</span></template>
					</el-input>

				</el-form-item>


				<div style="height: 20px; text-align: center; padding-top: 30px; padding-bottom: 20px;">
					<el-button :disabled="showbtn" type="primary" style="width: 100%;" @click="savePwd">更改密码</el-button>
				</div>
			</el-form>
		</el-dialog>



	</div>
</template>

<script>
	const aes = require("../utils/aes.js").default
	export default {
		data() {
			return {
				isShowModal: false,
				showbtn: false,
				bgidx: "",
				bgvideourl: "",
				EditItem: {},
				username: "",
				password: "",
				session_id: "",
				yzmUrl: "",
				yzm: "",
				showQrcode: false,
				qrcodeUrl: "",
				qrcodeChecking: false,
				qrcodeTimer: -1,
				pwdForm: {
					newPwd1: "",
					newPwd2: "",

				}
			}
		},
		mounted() {

			window.pmid = 3 //南位
			// this.getBgVideo()
			// this.getYZM()


			/**
			 * 南高职：1
			 * 南工程：2
			 * 招生平台：3（测试）
			 * 南财：4
			 * 高新园：5
			 **/
			// if(window.location.host.indexOf('local')!=-1||
			//     window.location.host.indexOf('127.0.0.1')!=-1||
			//     window.location.host.indexOf('8080')!=-1){
			//     window.pmid=1
			// }
		},
		methods: {
			getBgVideo() {

				this.$http.post(window.location.protocol + "//api-sch-saas.aiwx.org.cn/api/sch_weather", {
					pmid: 1
				}).then(res => {
					this.bgvideourl = res.data.theme.video_url
					this.bgidx = res.data.theme.img_url

				})
			},
			getQrcode() {
				this.$http.post("/api/login_qrcode", {
					pmid: window.pmid
				}).then(res => {

					this.session_id = res.data.session;

					this.qrcodeUrl = res.data.url;
					if (!this.qrcodeChecking) {
						this.qrcodeChecking = true

						this.checkQrcode();
					}
				})



			},
			checkQrcode() {

				this.qrcodeTimer = setInterval(() => {

					this.$http.post("/api/check_qrcode", {
						session_id: this.session_id,
						noloading: 1,
						pmid: window.pmid
					}).then(res => {
						if (res.data.token) {
							if (res.data.pmid != window.pmid) {
								this.$message({
									message: "错误的二维码",
									type: 'error',
									offset: '600'
								});
							} else {
								let menus = res.data.menus;
								delete res.data.menus
								sessionStorage.setItem("userinfo", JSON.stringify(res.data))
								if (!localStorage.getItem("year")) {
									localStorage.setItem("year", new Date().getFullYear())
								}
								sessionStorage.setItem("menus", menus)
								this.getMenus()
								clearInterval(this.qrcodeTimer)
								this.$router.push("/homePage")
							}

						}
					})
				}, 1000)
			},
			getYZM() {
				this.$http.post("/api/get_login_randomcode", {
					pmid: window.pmid
				}).then(res => {
					this.session_id = res.data.data.session_id;
					this.yzmUrl = res.data.data.svg;
				})


			},
			changeYzm() {
				this.getYZM()
			},
			login() {

				if (this.username.trim() && this.password.trim()) {

					this.$http.post("/api/usr_login", {
						loginName: this.username.trim(),
						pwd: aes.encrypt( this.password.trim())
					}).then(res => {
						if (res.data.loginName) {
							window.sessionStorage.setItem("username", res.data.loginName)
							window.sessionStorage.setItem("realName", res.data.teacherName)
							this.$router.push("/luqu")
						} else {
							this.$message({
								message: "登录失败，账号密码错误",
								type: 'error',
								offset: '600'
							});
						}
					})
					// if(this.username.trim()=="admin"&&this.password.trim()=="desfadmin"){

					// }else{
					// 	this.$message({
					// 			message: "登录失败，账号密码错误",
					// 			type: 'error',
					// 			offset: '600'
					// 		});
					// }


					// this.$http.post("api/admin_login", {
					// 	phone: this.username,
					// 	password: this.password,
					// 	pmid: window.pmid
					// }).then(res => {

					// 	if (res.data && res.data.data.token) {
					// 		sessionStorage.setItem("userinfo", JSON.stringify(res.data.data))
					// 		sessionStorage.setItem("auth",res.data.data.token)
					// 		this.$router.push("/homePage")

					// 	} else {
					// 		this.$message({
					// 			message: res.data.msg,
					// 			type: 'error',
					// 			offset: '600'
					// 		});

					// 	}


					// }, err => {

					// })
				} else {
					this.$message({
						message: "好像没有填完整~",
						type: 'error',
						offset: '600'
					});
				}
			},
			changeLoginType() {
				this.showQrcode = !this.showQrcode
				if (this.showQrcode && !this.qrcodeUrl) {
					this.getQrcode()
				}
			},

			checkPassWord(value) {
				if (value.length < 8 || value.length > 16) { //最初级别
					this.$message.error("密码长度为8-16位")
					return false;
				}
				if (!/\d/.test(value)) { //如果用户输入的密码 包含了数字
					this.$message.error("密码必须包含数字")
					return false;
				}
				if (!/[a-z]/.test(value) && !/[A-Z]/.test(value)) { //如果用户输入的密码 包含了小写的a到z
					this.$message.error("密码必须包含大小写字母")
					return false;
				}

				var regEn = /[`~!@#$%^&*()_+<>?:"{},.\/;'[\]]/im,
					regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im;

				if (!regEn.test(value) && !regCn.test(value)) {
					this.$message.error("密码必须包含特殊字符")
					return false;
				}

				return true


			},

			savePwd() {

				if (!this.pwdForm.newPwd1) {
					this.$message.error("请输入新密码")
					return
				}
				if (!this.pwdForm.newPwd2) {
					this.$message.error("请再次输入新密码")
					return
				}
				if (this.pwdForm.newPwd1 != this.pwdForm.newPwd2) {
					this.$message.error("两次密码不一致")
					return
				}
				if (this.checkPassWord(this.pwdForm.newPwd2)) {
					this.showbtn = true;
					this.$http.post("/api/reset_myself_pwd_first", this.pwdForm).then(ok => {
						if (ok.data) {
							this.$message.success("修改成功,即将跳转")
							setTimeout(() => {
								this.$router.push("/home")
							}, 3000)

						}
					})
				}


			},
		}
	}
</script>

<style scoped>
	.loginbg {
		width: 100%;
		height: 100%;
		background-image: url(../../public/img/bg.png);
		background-size: 100% 100%;

	}

	.login_box {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -180px;
		margin-top: -270px;
		border-radius: 4px;
		-moz-border-radius: 4px;
		-webkit-border-radius: 4px;
		background-color: #fff;
		width: 320px;
		height: 340px;
		padding: 20px;
		box-shadow: 0 2px 10px #999;
		-moz-box-shadow: #999 0 2px 10px;
		-webkit-box-shadow: #999 0 2px 10px;
	}

	.coricon {
		position: absolute;
		top: 0;
		right: 0;
		;
		width: 60px;
		height: 60px;
	}

	.coricon img {
		width: 100%;
		transform: scale(0.5, 0.5);
	}

	.bgvideo {
		position: fixed;
		width: 100%;
		height: 100%;
		top: -10;
		left: 0;
		z-index: -100;
	}
</style>